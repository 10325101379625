import React, { Fragment } from 'react';
import { Styled } from 'theme-ui';

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    A blog by <Styled.a href="https://www.hughfm.com/">Hugh Middleton</Styled.a>.
    <br />
    Software Developer &amp; Musician
  </Fragment>
);
